<template>
	<div id="newsletter">
		<form @submit.prevent="signUp">
			<div class="col-row">
				<div class="col s6 m6 l3">
					<div class="form-block">
						<label class="form-label required" data-key="firstname">
							<span class="label">Firstname</span>
							<div class="form-field input">
								<input v-model="model.firstname" type="text" class="field">
							</div>
						</label>
					</div>
				</div>
				<div class="col s6 m6 l3">
					<div class="form-block">
						<label class="form-label required" data-key="surname">
							<span class="label">Surname</span>
							<div class="form-field input">
								<input v-model="model.surname" type="text" class="field">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m12 l3">
					<div class="form-block">
						<label class="form-label required" data-key="email">
							<span class="label">Email address</span>
							<div class="form-field input">
								<input v-model="model.email" type="email" maxlength="64" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" class="field">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m12 l3">
					<div class="submit">
						<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Subscribing...' : 'Subscribe'" :disabled="sending"></button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
	import {newsletterApi} from '../../api';
	export default {
		data () {
			return {
				sending: false,
				model: {}
			}
		},
		methods: {
			subscribe() {
				let self = this;
				self.sending = true;
				$('span.error').remove();
				newsletterApi.subscribe(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						alert('Thank you for subscribing');
						self.initModel();
					}
				}, (error) => {
					self.sending = false;
					console.log(error);
					let res = error.response.data;
					if(res.error) {
						if(res.errors) {
							$.each(res.errors, function(key, error) {
								$('#newsletter [data-key="' + key + '"]').append('<span class="error">' + error + '</span>');
							});
						}
					}
					if(res.message) {
						//alert(res.message);
					}
				});
			},
			signUp() {
				let self = this;
				self.subscribe();
			},
			initModel() {
				let self = this;
				self.model = {
					firstname: "",
					surname: "",
					email: "",
				}
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>