var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "finance-calculator" } }, [
    _c("div", { staticClass: "modal" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("h4", { staticClass: "heading" }, [
        _vm._v("Calculate your treatment plan"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We understand that cost is an important consideration when you’re looking to straighten your smile. That’s why we offer 0% finance over 12 months, so you can spread the cost of your new Smmmile without having to pay any interest!"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We also offer longer term finance solutions (up to 5 years), at a typical rate of 9.9% APR, through an external partner. These options combine flexible choices of deposit values and durations, so you can adapt your finance agreement to your specific needs."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Use the calculator to see how affordable your Invisalign treatment could be! "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "If you have any questions please give us a call, book a free consultation, or pop into our store."
        ),
      ]),
      _vm._v(" "),
      !_vm.is_location
        ? _c(
            "a",
            {
              staticClass: "ui-btn ui-btn-rounded triggerModalLocationPopup",
              attrs: { href: "javascript:void(0);" },
            },
            [_vm._v("Book FREE consultation")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.is_location
        ? _c(
            "a",
            {
              staticClass: "ui-btn ui-btn-rounded triggerModalLocationPopup",
              attrs: { href: _vm.$app.dentally_link, target: "_blank" },
            },
            [_vm._v("Book FREE consultation")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "desposit-copy" }, [
        _vm._v("N.B. Minimum deposit of £100 required"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-block" }, [
        _c("div", { staticClass: "col-row" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "col l6 m6 s6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.treatment_cost,
                  expression: "treatment_cost",
                },
              ],
              attrs: {
                type: "number",
                pattern: "[0-9]*",
                step: "100",
                min: "0",
                max: _vm.invisalign_cost,
              },
              domProps: { value: _vm.treatment_cost },
              on: {
                change: function ($event) {
                  return _vm.calcFinance()
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.treatment_cost = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-row" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "col l6 m6 s6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deposit,
                  expression: "deposit",
                },
              ],
              attrs: {
                type: "number",
                pattern: "[0-9]*",
                step: "100",
                min: "100",
                max: _vm.treatment_cost,
              },
              domProps: { value: _vm.deposit },
              on: {
                change: function ($event) {
                  return _vm.calcFinance()
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.deposit = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-row" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "col l6 m6 s6" }, [
            _c("div", {
              staticClass: "amount",
              domProps: { innerHTML: _vm._s("&pound;" + _vm.finance_required) },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("div", { staticClass: "table-block" }, [
        _c("div", { staticClass: "col-row table-heading" }, [
          _c("div", { staticClass: "col l6 m6 s12" }, [
            _c("div", { staticClass: "label" }, [
              _c("strong", [
                _vm._v(
                  "Representative " + _vm._s(_vm.apr) + "% APR (Variable)"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(5),
        ]),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _vm._m(9),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "closeBtn modal-close" }, [
      _c("span", { staticClass: "fa fa-times" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col l6 m6 s6" }, [
      _c("div", { staticClass: "label" }, [
        _c("strong", [_vm._v("Treatment cost")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col l6 m6 s6" }, [
      _c("div", { staticClass: "label" }, [_c("strong", [_vm._v("Deposit")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col l6 m6 s6" }, [
      _c("div", { staticClass: "label" }, [
        _c("strong", [_vm._v("Finance required")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-block" }, [
      _c("div", { staticClass: "col-row table-heading" }, [
        _c("div", { staticClass: "col l6 m6 s12" }, [
          _c("div", { staticClass: "label" }, [
            _c("strong", [_vm._v("Interest free representative 0% APR")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col l6 m6 s12" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Monthly repayment")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-row" }, [
        _c("div", { staticClass: "col l6 m6 s6" }, [
          _c("div", { staticClass: "label" }, [_vm._v("6 months")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col l6 m6 s6" }, [
          _c("div", { staticClass: "amount", attrs: { id: "amount-6m" } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-row" }, [
        _c("div", { staticClass: "col l6 m6 s6" }, [
          _c("div", { staticClass: "label" }, [_vm._v("10 months")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col l6 m6 s6" }, [
          _c("div", { staticClass: "amount", attrs: { id: "amount-10m" } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-row" }, [
        _c("div", { staticClass: "col l6 m6 s6" }, [
          _c("div", { staticClass: "label" }, [_vm._v("12 months")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col l6 m6 s6" }, [
          _c("div", { staticClass: "amount", attrs: { id: "amount-12m" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col l6 m6 s12" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Monthly repayment")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-row" }, [
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "label" }, [_vm._v("24 months")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "amount", attrs: { id: "amount-24m" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-row" }, [
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "label" }, [_vm._v("36 months")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "amount", attrs: { id: "amount-36m" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-row" }, [
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "label" }, [_vm._v("48 months")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "amount", attrs: { id: "amount-48m" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-row" }, [
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "label" }, [_vm._v("60 months")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col l6 m6 s6" }, [
        _c("div", { staticClass: "amount", attrs: { id: "amount-60m" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }