var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "newsletter" } }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.signUp.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-row" }, [
          _c("div", { staticClass: "col s6 m6 l3" }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                {
                  staticClass: "form-label required",
                  attrs: { "data-key": "firstname" },
                },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("Firstname")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-field input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.firstname,
                          expression: "model.firstname",
                        },
                      ],
                      staticClass: "field",
                      attrs: { type: "text" },
                      domProps: { value: _vm.model.firstname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "firstname", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s6 m6 l3" }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                {
                  staticClass: "form-label required",
                  attrs: { "data-key": "surname" },
                },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("Surname")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-field input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.surname,
                          expression: "model.surname",
                        },
                      ],
                      staticClass: "field",
                      attrs: { type: "text" },
                      domProps: { value: _vm.model.surname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "surname", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m12 l3" }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                {
                  staticClass: "form-label required",
                  attrs: { "data-key": "email" },
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Email address"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-field input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.email,
                          expression: "model.email",
                        },
                      ],
                      staticClass: "field",
                      attrs: {
                        type: "email",
                        maxlength: "64",
                        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$",
                      },
                      domProps: { value: _vm.model.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m12 l3" }, [
            _c("div", { staticClass: "submit" }, [
              _c("button", {
                staticClass: "ui-btn ui-btn-block",
                attrs: { type: "submit", disabled: _vm.sending },
                domProps: {
                  textContent: _vm._s(
                    _vm.sending ? "Subscribing..." : "Subscribe"
                  ),
                },
              }),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }