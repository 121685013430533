<template>
	<form @submit.prevent="postDentalReferral">
		<div class="col-row auto-col-clear">
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="firstname">
						<span class="label">Firstname</span>
						<div class="form-field input">
							<input v-model="model.firstname" type="text" class="field" placeholder="Firstname">
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="surname">
						<span class="label">Surname</span>
						<div class="form-field input">
							<input v-model="model.surname" type="text" class="field" placeholder="Surname">
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="email">
						<span class="label">Email address</span>
						<div class="form-field input">
							<input v-model="model.email" type="email" maxlength="64" class="field" placeholder="Email address">
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="dob">
						<span class="label">Date of Birth</span>
						<div class="form-field input">
							<input v-model="model.dob" type="text" class="field" @input="formatDOB" placeholder="DD-MM-YYYY">
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="contact_number">
						<span class="label">Contact number</span>
						<div class="form-field input">
							<input type="tel" v-model="model.contact_number" placeholder="Contact number" class="field" pattern="^([0-9])+$" minlength="11" maxlength="11">
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="treatment_required">
						<span class="label">Treatment required</span>
						<div class="form-field select">
							<select class="field" v-model="model.treatment_required">
								<option value="" selected disabled>Select Treatment</option>
								<option value="Adult Orthodontics">Adult Orthodontics</option>
								<option value="Teen Orthodontics">Teen Orthodontics</option>
								<option value="Implant">Implant</option>
							</select>
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="practice_name">
						<span class="label">Practice name</span>
						<div class="form-field input">
							<input v-model="model.practice_name" type="text" class="field" placeholder="Practice name">
						</div>
					</label>
				</div>
			</div>
			<div class="col s6 m6 l6">
				<div class="form-block">
					<label class="form-label required" data-key="referring_dentists_name">
						<span class="label">Referring Dentists Name</span>
						<div class="form-field input">
							<input v-model="model.referring_dentists_name" type="text" class="field" placeholder="Referring Dentists Name">
						</div>
					</label>
				</div>
			</div>
			<div class="col s12 m12 l12">
				<div class="form-block">
					<label class="form-label required" data-key="additional_info">
						<span class="label">Additional Information</span>
						<div class="form-field textarea">
							<textarea v-model="model.additional_info" type="text" class="field" placeholder="Additional Information" cols="4" rows="4"></textarea>
						</div>
					</label>
				</div>
			</div>
		</div>
		<div class="submit">
			<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Submitting...' : 'Submit'" :disabled="sending"></button>
		</div>
	</form>
</template>
<script>
	import {contactApi} from '../../api';

	export default {
		data () {
			return {
				sending: false,
				model: {}
			}
		},
		methods: {
			formatDOB() {
				let self = this;
				let value = self.model.dob.replace(/\D/g, '');
				if (value.length >= 2) value = value.slice(0, 2) + '-' + value.slice(2);
				if (value.length >= 5) value = value.slice(0, 5) + '-' + value.slice(5, 9);
				self.model.dob = value.slice(0, 10);
			},
			postDentalReferral() {
				let self = this;
				self.sending = true;
				$('span.error').remove();

				contactApi.postDentalReferral(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						alert("Thank you! Your message has been successfully sent");
						self.initModel();
					}
				}, (error) => {
					self.sending = false;
					console.log(error);
					let res = error.response.data;
					if(res.error && res.errors) {
						Object.entries(res.errors).forEach((value, index) => {
							$('[data-key="' + value[0] + '"]').append('<span class="error">' + value[1] + '</span>');
						});
					} else {
						if(res.message) {
							alert(res.message);
						}
					}
				});
			},
			initModel() {
				let self = this;
				self.model = {
					firstname: "",
					surname: "",
					email: "",
					dob: "",
					contact_number: "",
					treatment_required: "",
					practice_name: "",
					referring_dentists_name: "",
					additional_info: ""
				}
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>