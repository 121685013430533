import 'owl.carousel';
import helper from './helpers';
import {recaptchaApi} from './api';
import '@fancyapps/fancybox';
import 'zurb-twentytwenty/js/jquery.event.move.js';
import 'zurb-twentytwenty/js/jquery.twentytwenty.js';
import {camelCase, upperFirst} from 'lodash';
// custom html video
function customHtmlVideo() {
	$('video.html-player').each(function() {
		var vid = $(this);
		var play = false;
		vid.wrap('<div class="custom-html-player"></div>');
		var wrap = vid.parent('.custom-html-player');
		wrap.prepend('<div class="overlay"></div>');
		wrap.append('<div class="icon"><span class="fa fa-play"></span></div>');

		wrap.find('.icon, .overlay').on('click', function() {
			if(wrap.hasClass('playing')) {
				play = false;
			} else if(wrap.hasClass('paused')) {
				play = true;
			} else {
				play = true;
			}
			checkPlayStatus();
		});

		function checkPlayStatus() {
			if(play == true) {
				vid[0].play();
				wrap.removeClass('paused').addClass('playing');
			} else {
				vid[0].pause();
				wrap.removeClass('playing').addClass('paused');
			}
		}
	});
}
// mobile nav
function activateNavMenu() {
	$('#nav-menu').on('click', function() {
		var self = $(this), 
			app = $('#app'),
			activeClass = 'menu-active';
		app.toggleClass(activeClass);
		self.toggleClass('fa-times');
		if(app.hasClass(activeClass)) {
			$('#nav a').on('click', function() {
				app.removeClass(activeClass);
				self.removeClass('fa-times');
			});
		}
	});
}
// scroll header
function ScrollHeader() {
	var scrollTarget = $('#header'),
		scrollClass = 'collapse';
	if( helper.isElementScrolledTo(scrollTarget[0].id) ) {
		scrollTarget.addClass(scrollClass);
	} else {
		scrollTarget.removeClass(scrollClass);
	}
}
// scroll foot ctas
function ScrollFooterCtas() {
	var scrollTarget = $('#foot-ctas'),
		scrollClass = 'collapse',
		body = $('body');
	if(!body.hasClass('page-template-default') && !body.hasClass('error404')) {
		var detect = function() {
			if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - scrollTarget.outerHeight())) {
				scrollTarget.addClass(scrollClass);
			} else {
				scrollTarget.removeClass(scrollClass);
			}
		}
		detect();
		$(window).on('scroll', detect);
	}
}
// ui toggle
function enableUIToggle() {
	var toggle = $('.ui-toggle');
	toggle.each(function() {
		var load = $(this).data('toggle');
		if(load == 'show') {
			$(this).find('.ui-toggle-content').css("display", "block");
		}
	});
	$('.ui-toggle-title').on('click', function() {
		$(this).next('.ui-toggle-content').toggle();
	});
}
// checkbox access
function doCheckboxAccess() {
	$('.ui-checkbox input').on('keypress', function(e) {
		e.preventDefault();
		if(e.keyCode === 13) {
			$(this).prop('checked', !$(this).prop('checked'));
		}
	});
}
// reposition owl nav buttons
function repositionOwlNav() {
	var owl = $('.owl-carousel.owl-loaded');
	var imgHeight = owl.find('img').height();
	owl.find('.owl-nav button').css("top", imgHeight / 2);
}
// init reviews slider
function initReviewsSlider() {
	var owl = $('#carousel-reviews .slider');
	owl.owlCarousel({
		loop: true,
		nav: false,
		dots: true,
		items: 1,
		autoHeight: true,
		navText: [
			"<span class='fa fa-chevron-left'></span>",
			"<span class='fa fa-chevron-right'></span>"
		],
	});
}
// initGoogleReviewsSlider
function initGoogleReviewsSlider() {
	var owl = $('#google-widget-reviews .slider');
	owl.owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		items: 1,
		autoHeight: false,
		navText: [
			"<span class='fa fa-chevron-left'></span>",
			"<span class='fa fa-chevron-right'></span>"
		],
	});
}
// init owl slider
function initOwlSlider() {
	var owl = $('.slider .owl-carousel');
	owl.owlCarousel({
		loop: false,
		nav: true,
		dots: false,
		navText: [
			"<span class='fa fa-chevron-left'></span>",
			"<span class='fa fa-chevron-right'></span>"
		],
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			800: {
				items: 3
			},
			1000: {
				items: parseInt(owl.data('desktop-items')) || 4
			}
		}
	});
	setTimeout(function() {
		repositionOwlNav();
	}, 1000);
}
// back to top
function backToTop() {
	var el = $('#backToTop');
	el.on('click', function() {
		$("html, body").animate({
			scrollTop: 0
		}, 500);
	});
	$(window).on('scroll', function() {
		if ($(this).scrollTop() > 300) {
			el.fadeIn();
		} else {
			el.fadeOut();
		}
	});
}
// smooth scroll
function smoothScroll() {
	$('a[href^="#"]').on('click', function(event) {
		var target = $(this.getAttribute('href'));
		if( target.length ) {
			event.preventDefault();
			$('html, body').stop().animate({
				scrollTop: target.offset().top - ($('#header.collapse').outerHeight() || 0)
			}, 1000);
		}
	});
}
// triggerModal
function triggerModal(targetname) {
	var $class = 'active',
		$targetname = targetname,
		$target = $('#' + targetname),
		$bodyClass = 'modal-active modal-' + targetname,
		$modal = $('.triggerModal' + _.upperFirst(_.camelCase(targetname)));
	$modal.on('click', function() {
		$target.addClass($class);
		$('body').addClass($bodyClass);
		if($target.hasClass($class)) {
			$target.find('.modal-close').on('click', function() {
				$target.removeClass($class);
				$('body').removeClass($bodyClass);
			});
		}	
		$target.on('click', function(e) {
			if(e.target.id === targetname) {
				$target.removeClass($class);
				$('body').removeClass($bodyClass);
			}
		});
	});
}
// show location
function showLocations() {
	$('.location-names > a').on('click', function() {
		var idx = ($(this).index() + 2);
		$(this).addClass('active').siblings('a').removeClass('active');
		$('.list-locations .location:nth-child(' + idx + ')').addClass('active').siblings('.location').removeClass('active');
		return false;
	});
}
// on book consulation
function onBookConsultationSubmit() {
	var modal = $('#book-consultation');
	modal.find('form').on('submit', function() {
		var form = $(this),
			ct = form.find('#your-contact-time select').val(),
			ctp = form.find('#your-contact-time-specific input').val();
		form.find('input[name="preferred_time"]').val(ct + (ctp ? ' | ' + ctp : ''));
	});
	modal.find('#your-store select').on('change', function() {
		modal.find('input[name="token"]').val($(this).val());
		modal.find('input[name="treatment"]').val($(this).find("option:selected").data("treatment"));
	});
}
// on location popup
function onLocationPopup() {
	var popup = $('#location-popup');
	popup.find('.close').on('click', function() {
		popup.remove();
	});
	popup.find('.submit').on('click', function() {
		$(this).attr('href', popup.find('select').val());
	});
}
jQuery(document).ready(function($) {
	// custom html video
	customHtmlVideo();
	// scroll header
	ScrollHeader();
	// ScrollFooterCtas
	ScrollFooterCtas();
	// smooth scroll
	smoothScroll();
	// init owl slider
	initOwlSlider();
	// init reviews slider
	initReviewsSlider();
	initGoogleReviewsSlider();
	// mobile nav
	activateNavMenu();
	// tabs
	helper.enableTabs();
	// ui select
	helper.enableUISelect();
	// ui toggle
	enableUIToggle();
	// checkbox access
	doCheckboxAccess();
	// back to top
	backToTop();
	// onBookConsultationSubmit
	onBookConsultationSubmit();
	// show locations
	showLocations();
	// trigger modal
	triggerModal('finance-calculator');
	triggerModal('book-consultation');
	triggerModal('location-popup');
	if(helper.$_GET('finance') == 1) {
		setTimeout(function() {
			$('.triggerModalFinanceCalculator').one().trigger('click');
		});
	}
	// before and after
	setTimeout(function() {
		$(".before-after").twentytwenty();
	}, 500);
	// on location popup
	onLocationPopup();
	// on scroll
	$(window).on('scroll', function() {
		// if scroll meets end of page, stop scrolling
		if($(window).scrollTop() + $(window).height() != $(document).height()) {
			ScrollHeader();
		}
	});
	// on resize
	$(window).on('resize', function() {
		setTimeout(function() {
			repositionOwlNav();
		}, 1000);
	});
	// fancybox
	$('[data-fancybox]').fancybox();
	// recaptcha
	setTimeout(() => {
		helper.getRecaptchaToken();
	}, 1000);
	/*$('#book-consultation form').on('submit', function(e) {
		e.preventDefault();
		var token = $(this).find('.g-recaptcha-response').val();
		if(token) {
			recaptchaApi.validateRecaptcha(token).then(res => {
				if(res.data.score >= 0.5 && res.data.success) {
					$(this)[0].submit();
				} else {
					alert("Seems like your a robot");
				}
				// fetch new token
				helper.getRecaptchaToken();
			});
		} else {
			alert("Please try again later thanks.");
		}
	});*/
});