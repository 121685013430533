<template>
	<div id="invisalign-finance-calculator">
		<div class="table-block">
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label"><strong>Treatment cost</strong></div>
				</div>
				<div class="col l6 m6 s6">
					<input type="number" pattern="[0-9]*" step="100" min="0" :max="invisalign_cost" v-model="treatment_cost" @change="calcFinance()">
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label"><strong>Deposit</strong></div>
				</div>
				<div class="col l6 m6 s6">
					<input type="number" pattern="[0-9]*" step="100" v-model="deposit" @change="calcFinance()" min="100" :max="treatment_cost">
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label"><strong>Finance required</strong></div>
				</div>
				<div class="col l6 m6 s6">
					<div class="amount" v-html="'&pound;' + finance_required"></div>
				</div>
			</div>
		</div>
		<div class="table-block">
			<div class="col-row table-heading">
				<div class="col l6 m6 s12">
					<div class="label"><strong>Interest free representative 0% APR</strong></div>
				</div>
				<div class="col l6 m6 s12">
					<div class="label">Monthly repayment</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">6 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-6m" class="amount"></div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">10 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-10m" class="amount"></div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">12 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-12m" class="amount"></div>
				</div>
			</div>
		</div>
		<div class="table-block">
			<div class="col-row table-heading">
				<div class="col l6 m6 s12">
					<div class="label"><strong>Representative {{apr}}% APR (Variable)</strong></div>
				</div>
				<div class="col l6 m6 s12">
					<div class="label">Monthly repayment</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">24 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-24m" class="amount"></div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">36 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-36m" class="amount"></div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">48 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-48m" class="amount"></div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s6">
					<div class="label">60 months</div>
				</div>
				<div class="col l6 m6 s6">
					<div id="amount-60m" class="amount"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			is_location: {
				default: false,
				required: false,
			},
			invisalign_cost: {
				required: false,
				default: 2495
			}
		},
		data() {
			return {
				treatment_cost: 0,
				deposit: 900,
				finance_required: 0,
				apr: 9.9
			}
		},
		methods: {
			calcFinance() {
				let self = this;
				// if desposit empty, re-assign as 0
				self.deposit = self.deposit || 0;
				var amountToBorrow = self.finance_required = (self.treatment_cost - self.deposit).toFixed(2);
				$('#amount-6m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 6, 0).toFixed(2));
				$('#amount-10m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 10, 0).toFixed(2));
				$('#amount-12m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 12, 0).toFixed(2));
				$('#amount-24m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 24, self.apr).toFixed(2));
				$('#amount-36m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 36, self.apr).toFixed(2));
				$('#amount-48m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 48, self.apr).toFixed(2));
				$('#amount-60m').html("&pound;" + self.calcAprMonthlyRepayment(amountToBorrow, 60, self.apr).toFixed(2));
			},
			calcAprMonthlyRepayment(amountToBorrow, numberOfMonths, interestRate) {
				if(interestRate === 0) {
					return amountToBorrow / numberOfMonths;
				} else {
					var interestRateAsDecimal = interestRate / 100, 
						topLineEq = (Math.pow(1 + interestRateAsDecimal, 1 / 12) - 1) * amountToBorrow, 
						bottomLineEq = 1 - Math.pow(Math.pow(1 + interestRateAsDecimal, 1 / 12), -Math.abs(numberOfMonths));
					return topLineEq / bottomLineEq;
				}
			},
			initMounted() {
				let self = this;
				self.treatment_cost = self.invisalign_cost || 0;
				self.calcFinance();
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>