import Config from '../config';
let helper = {
	days() {
		var days = 31,
			i = 1,
			arr = [];
		for (i; i <= days; i++) {
			arr.push(i);
		}
		return arr;
	},
	months: [
		{ name: "January", id: 1 },
		{ name: "February", id: 2 },
		{ name: "March", id: 3 },
		{ name: "April", id: 4 },
		{ name: "May", id: 5 },
		{ name: "June", id: 6 },
		{ name: "July", id: 7 },
		{ name: "August", id: 8 },
		{ name: "September", id: 9 },
		{ name: "October", id: 10 },
		{ name: "November", id: 11 },
		{ name: "December", id: 12 },
	],
	getYears(amount, order, offset = 0) {
		var date = new Date(),
			cur_year = date.getFullYear(),
			length = (amount) ? amount : 100,
			i = 0,
			arr = [];
		if(order === 'past') {
			cur_year -= offset;
		}
		if(order === 'future') {
			cur_year += offset;
		}
		for (i; i < length; i++) {
			if(order === 'past') {
				arr.push(cur_year--);
			}
			if(order === 'future') {
				arr.push(cur_year++);
			}
		}
		return arr;
	},
	get_year() {
		return new Date().getFullYear();
	},
	$_GET(name, url) {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	},
	getUrlParams() {
		var q = window.location.href.split('?')[1].split('#')[0].split('&');
		var dict = {};
		var elem = [];
		for (var i = q.length - 1; i >= 0; i--) {
			elem = q[i].split('=');
			dict[elem[0]] = elem[1];
		};
		return dict;
	},
	setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	},
	getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		if (parts.length == 2) return parts.pop().split(";").shift();
	},
	enableTabs() {
		$('span.ui-tab-btn:first-of-type').addClass('active');
		$('.ui-tabs span.ui-tab-btn').on('click', function() {
			var data_start = $(this).parent().data('start-tabs') || 0,
				index = $(this).index(),
				tabWrappper = $(this).parents('.ui-tabs');
			index = data_start ? index - data_start : index;
			tabWrappper.find('span.ui-tab-btn').removeClass('active');
			$(this).addClass('active');
			tabWrappper.find('.ui-tab').hide().eq(index).show();
		});
	},
	appendErrors(errors) {
		Object.keys(errors).forEach((i) => {
			$('[data-field="' + i + '"]').append('<span class="error">' + errors[i] + '</span>');
		});
	},
	isElementScrolledTo(elem) {
		var elementTarget = document.getElementById(elem);
		return (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight));
	},
	getRecaptchaToken() {
		grecaptcha.ready(function () {
			grecaptcha.execute(Config.config.recaptcha.dengro_site_key, { action: 'contact' }).then(token => {
				$('.g-recaptcha-response').val(token);
			});
		});
	},
	enableUISelect() {
		let self = this;
		$('.ui-select').on('click', function() {
			var select = $(this),
				drop = select.find('.dropdown');
			select.toggleClass('active');
			select.on('mouseleave', function() {
				// remove dropdown active on select leave
				select.removeClass('active');
				// if user selected ui values, remove error class
				if( self.get_ui_select_values(select.find('input:checkbox').attr('name')) ) {
					select.removeClass('error');
				}
			});
		});
	},
	get_ui_select_values(name) {
		var values = [];
		$("input[name='" + name + "']:checked").each(function(){
			values.push($(this).val());
		});
		return values;
	},
	array_chunkable(array = [], chunk_size = [], preserve_keys = false) {
		var chunkInt = 0;
		var chunkedArray = [];
		chunk_size.forEach((value, key) => {
			for( var i = 0; i < value; i++ ) {
				if( preserve_keys ) {
					$chunkedArray[key][chunkInt].push(array[chunkInt]);
				} else {
					$chunkedArray[key].push(array[chunkInt]);
				}
				chunkInt++;
			}
		});
		return chunkedArray;
	},
	scrollTop() {
		if('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}
		window.scrollTo(0,0);
	},
	scrollToElement(el) {
		$(el)[0].scrollIntoView(true);
	},
	isTypedTextNumber(evt) {
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}

export default helper;